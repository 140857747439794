<template>
    <b-card>
        <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
                <b-card-title></b-card-title>
                <b-card-sub-title></b-card-sub-title>
            </div>
            <!-- Card Actions -->
            <div class="heading-elements">
                <ul class="list-inline mb-0">
                    <li>
                        <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
                    </li>
                </ul>
            </div>
        </div>
        <div>
      <b-sidebar id="sidebar-1" title="Carga de Archivos" shadow bg-variant="dark" text-variant="light" width="450px"
        right backdrop>
        <div class="px-2 py-2">
            <p>La plataforma cuenta con una vista especialmente diseñada para la carga masiva de datos que son esenciales para su correcto funcionamiento. Esta vista permite la carga masiva de dos tipos de datos: Demanda Mensual y de SKUs.</p>
            <p>En el caso de la Demanda Mensual, el objetivo es cargar grandes cantidades de nueva demanda para un mes determinado y utilizarla posteriormente en la planificación. Es importante destacar que, en caso que se cargue información repetida para una misma combinación de año, mes y SKU, se creará un nuevo registro de demanda y no se reescribirá la información anterior.</p>
            <p>En cuanto a los SKUs, la finalidad de cargarlos masivamente es para actualizar las características y la asignación de líneas de producción. En este caso, si al cargar la información se repite la SKU, la información será reescrita.</p>
            <p>En ambos casos, se pone a disposición de los usuarios una plantilla de Excel con el formato de carga de la demanda, lo que facilita el proceso de carga masiva. Sin embargo, es importante tener en cuenta que la carga de datos está sujeta a permisos de carga.</p>
        </div>
      </b-sidebar>
    </div>
        <b-card-body>
            <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left">
                <b-tab v-for="fileUploadTab in fileUploadTabs" :title="fileUploadTab.tabName"
                    :key="fileUploadTab.tabName">
                    <b-card-text>
                        <b-card-title>
                            Cargar Documento {{ fileUploadTab.tabName }}
                        </b-card-title>
                        <!-- info -->
                        <b-alert variant="secondary" show>
                            <div class="alert-body">
                                <span></span>
                                <b-link target="_blank" @click="downloadTemplateFile(fileUploadTab.templateFile)">
                                    Descargar Ejemplo
                                </b-link>
                            </div>
                        </b-alert>
                        <b-row class="match-height">
                            <b-col md="8">
                                <b-form-file ref="form-file-inputs"
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    :placeholder="'Selecciona ' + fileUploadTab.tabName"
                                    drop-placeholder="Arrastra el archivo hasta acá ..." v-model="fileUploadTab.file">
                                </b-form-file>
                            </b-col>
                            <b-col>
                                <b-button variant="outline-primary" :disabled="!fileUploadTab.file"
                                    @click="uploadDocument(fileUploadTab)">
                                    <b-spinner v-if="fileUploadTab.isUploading" small class="mr-50" />
                                    <feather-icon v-else icon="UploadIcon" class="mr-50" />
                                    Cargar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card-body>
    </b-card>
</template>
<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BIconNodeMinusFill,
    BTabs,
    BSpinner,
    BAlert,
    BTab,
    BLink,
    VBPopover,
    VBTooltip,
    BFormGroup,
    BFormInvalidFeedback,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import useApiServices from "@/services/useApiServices.js";

import axios from "@axios";

export default {
    name: "CargaData",
    data() {
        return {
            fileUploadTabs: [
                {
                    tabName: "Demanda",
                    type: "DEMANDA",
                    file: null,
                    isUploading: false,
                    templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=DEMANDA",
                },
                {
                    tabName: "Skus",
                    type: "SKUS",
                    file: null,
                    isUploading: false,
                    templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=SKUS",
                },
               
            ],
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BCardHeader,
        BFormFile,
        BCardTitle,
        BCardSubTitle,
        BCardText,
        BCardBody,
        BFormSelect,
        BTabs,
        VBPopover,
        VBTooltip,
        BTab,
        BLink,
        BAlert,
        BSpinner,
        BFormInvalidFeedback,
        BFormGroup,
    },

    directives: {
        "b-tooltip": VBTooltip,
        "b-popover": VBPopover,
    },

    mounted() {
       
        
    },

    methods: {
        downloadTemplateFile(url) {
            return useApiServices.getDownloadFile(url);
        },
        uploadDocument(fileUploadTab) {
            var formData = new FormData();
            formData.append("documentFile", fileUploadTab.file);
            formData.append("documentType", fileUploadTab.type);

            if (fileUploadTab.file) {
                fileUploadTab.isUploading = true;

                useApiServices.postUploadDocument(formData, this)
                    .then((response) => {
                        console.log(response);
                        fileUploadTab.isUploading = false;

                        this.$toast(
                            {
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: `Carga Exitosa`,
                                    icon: "UploadIcon",
                                    variant: "success",
                                    html: `El documento <b>${fileUploadTab.file.name}</b> se ha cargado exitosamente.`,
                                },
                            },
                            {
                                onClose: () => {
                                    fileUploadTab.file = null;
                                },
                            }
                        );
                    })
                    .catch((error) => {
                        fileUploadTab.isUploading = false;

                        this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                                title: `Ocurrio un Error`,
                                icon: "UploadIcon",
                                variant: "danger",
                                html: `Ha ocurrido un error al subir el documento <b>${fileUploadTab.file.name}</b>.`,
                            },
                        });
                    });
            } else {
            }
        },
    },
};
</script>

<style scoped>
.carga-data {
    margin-top: 4%;
}
</style>

